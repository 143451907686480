import { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import { omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Input, Modal, useUrlParams } from 'components';
import { modalContainer } from './styles';

const filtersToClear = ['fiatPriceFrom', 'fiatPriceTo', 'cryptoPriceFrom', 'cryptoPriceTo'];

const BookingsFiltersModal = forwardRef((props, ref) => {
  const { queryParams, clearQueryParams, setQueryParams } = useUrlParams();
  const { getText } = useTranslations();
  const { fiatPriceFrom, fiatPriceTo, cryptoPriceFrom, cryptoPriceTo } = queryParams;
  const modalRef = useRef();
  const [selectedFilters, setSelectedFilters] = useState({
    fiatPriceFrom: queryParams?.fiatPriceFrom ?? null,
    fiatPriceTo: queryParams?.fiatPriceTo ?? null,
    cryptoPriceFrom: queryParams?.cryptoPriceFrom ?? null,
    cryptoPriceTo: queryParams?.cryptoPriceTo ?? null,
  });

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  const handleClearAll = () => {
    clearQueryParams(filtersToClear);
    queryParams.current = omit(queryParams, filtersToClear);
    setQueryParams(queryParams.current);
    setSelectedFilters({
      fiatPriceFrom: null,
      fiatPriceTo: null,
      cryptoPriceFrom: null,
      cryptoPriceTo: null,
    });
    modalRef.current.close();
  };

  const handleApplyFilters = async () => {
    queryParams.current = { ...queryParams.current, ...selectedFilters, pageIndex: 1, pageSize: 10 };
    setQueryParams(queryParams.current);
    modalRef.current?.close();
  };

  return (
    <Modal ref={modalRef} closeIcon css={modalContainer}>
      <h4 className="title">{getText('filters')}</h4>
      <p className="subtitle">{getText('selectFiltersToApply')}</p>
      <hr className="horizontal-line" />

      <div className="action-container">
        <Button type="link" className="clear-button" onClick={handleClearAll}>
          {getText('clearAll')}
        </Button>
        <Button onClick={handleApplyFilters}>{getText('applyFilters')}</Button>
      </div>

      <div className="scrollable-container">
        <h5 className="section-title">{getText('amount')}</h5>
        <div className="amount-container">
          <Input
            type="number"
            className="amount-input"
            placeholder={getText('minimum')}
            value={fiatPriceFrom ?? null}
            leftIcon={{ iconName: 'euro_symbol', className: 'euro-symbol-styles', color: 'black' }}
            onChange={(value) =>
              setSelectedFilters((prev) => ({ ...prev, fiatPriceFrom: value === '' ? null : Number(value) }))
            }
          />
          <Icon iconName="remove" className="remove-icon" />
          <Input
            type="number"
            className="amount-input"
            placeholder={getText('maximum')}
            value={fiatPriceTo ?? null}
            leftIcon={{ iconName: 'euro_symbol', className: 'euro-symbol-styles', color: 'black' }}
            onChange={(value) =>
              setSelectedFilters((prev) => ({ ...prev, fiatPriceTo: value === '' ? null : Number(value) }))
            }
          />
        </div>

        <div className="amount-container">
          <Input
            type="number"
            className="crypto-price-amount-input"
            placeholder={getText('minimum')}
            value={cryptoPriceFrom ?? null}
            leftIcon={{ iconName: 'ONE', className: 'crypto-price-symbol-styles', color: 'black' }}
            onChange={(value) =>
              setSelectedFilters((prev) => ({ ...prev, cryptoPriceFrom: value === '' ? null : Number(value) }))
            }
          />
          <Icon iconName="remove" className="remove-icon" />
          <Input
            type="number"
            className="crypto-price-amount-input"
            placeholder={getText('maximum')}
            value={cryptoPriceTo ?? null}
            leftIcon={{ iconName: 'ONE', className: 'crypto-price-symbol-styles', color: 'black' }}
            onChange={(value) =>
              setSelectedFilters((prev) => ({ ...prev, cryptoPriceTo: value === '' ? null : Number(value) }))
            }
          />
        </div>
      </div>
    </Modal>
  );
});

export default BookingsFiltersModal;
