import { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { isEmpty, omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, Icon, Input, Modal, useUrlParams, LabelCountCheck } from 'components';
import { TRANSACTION_KINDS, TRANSACTION_SUB_KINDS } from 'enums';
import { modalContainer } from './styles';

const filtersToClear = ['kinds', 'subKind', 'amountFrom', 'amountTo'];

const TransactionsFiltersModal = forwardRef((props, ref) => {
  const { queryParams, clearQueryParams, setQueryParams } = useUrlParams();
  const { getText } = useTranslations();
  const modalRef = useRef();
  const [selectedFilters, setSelectedFilters] = useState({
    kinds: queryParams.kinds ?? [],
    subKind: queryParams.subKind ?? null,
    amountFrom: queryParams?.amountFrom ?? null,
    amountTo: queryParams?.amountTo ?? null,
  });

  const kindsOptions = Object.values(TRANSACTION_KINDS).map((el) => ({ name: el, id: el }));
  const subKindsOptions = Object.values(TRANSACTION_SUB_KINDS)
    .map((el) => ({ name: el, id: el }))
    .filter((el) => el.id !== TRANSACTION_SUB_KINDS.OTHER);

  const disableAmountInputs =
    (!isEmpty(selectedFilters?.kinds?.filter((el) => el === TRANSACTION_KINDS.BOOKING_REFUND)) &&
      selectedFilters?.subKind === TRANSACTION_SUB_KINDS.BOOKING_REFUND_OF_CASH &&
      selectedFilters?.kinds?.length === 1) ||
    (!isEmpty(selectedFilters?.kinds?.filter((el) => el === TRANSACTION_KINDS.BOOKING_PAYMENT)) &&
      selectedFilters?.subKind === TRANSACTION_SUB_KINDS.BOOKING_PAYMENT_WITH_CASH &&
      selectedFilters?.kinds?.length === 1) ||
    (!isEmpty(selectedFilters?.kinds?.filter((el) => el === TRANSACTION_KINDS.BOOKING_PAYMENT)) &&
      selectedFilters?.subKind === TRANSACTION_SUB_KINDS.BOOKING_PAYMENT_WITH_CRYPTO &&
      selectedFilters?.kinds?.length === 1);

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  useEffect(() => {
    if (!disableAmountInputs) {
      setSelectedFilters((prev) => ({
        ...prev,
        amountFrom: null,
        amountTo: null,
      }));
    }

    setQueryParams(queryParams.current, selectedFilters);
  }, [disableAmountInputs]);

  const handleClearAll = () => {
    clearQueryParams(filtersToClear);
    queryParams.current = omit(queryParams, filtersToClear);
    setQueryParams(queryParams.current);
    setSelectedFilters({ kinds: [], subKind: null, amountFrom: null, amountTo: null });
    modalRef.current.close();
  };

  const handleApplyFilters = async () => {
    queryParams.current = { ...queryParams.current, ...selectedFilters, pageIndex: 1, pageSize: 10 };
    setQueryParams(queryParams.current);
    modalRef.current?.close();
  };

  const filterItems = (items, element) =>
    items?.some((el) => el === element) ? items?.filter((el) => el !== element) : [...items, element];

  return (
    <Modal ref={modalRef} closeIcon css={modalContainer}>
      <h4 className="title">{getText('filters')}</h4>
      <p className="subtitle">{getText('selectFiltersToApply')}</p>

      <hr className="horizontal-line" />

      <div className="action-container">
        <Button type="link" className="clear-button" onClick={handleClearAll}>
          {getText('clearAll')}
        </Button>
        <Button onClick={handleApplyFilters}>{getText('applyFilters')}</Button>
      </div>

      <div className="scrollable-container">
        <h5 className="section-title">{getText('kindOfTransaction')}</h5>

        <LabelCountCheck
          labelFilter="kinds"
          className="kinds-container"
          selectedOptions={selectedFilters?.kinds ?? []}
          options={kindsOptions}
          onChange={(value) => setSelectedFilters((prev) => ({ ...prev, kinds: filterItems(prev?.kinds, value?.id) }))}
        />

        <LabelCountCheck
          className="kinds-container"
          selectedOptions={selectedFilters?.subKind ?? null}
          showResults={2}
          options={subKindsOptions}
          onChange={(value) => setSelectedFilters((prev) => ({ ...prev, subKind: value?.name ? value?.name : null }))}
        />

        <div className="horizontal-section" />
        <h5 className="section-title">{getText('amount')}</h5>

        <div className="amount-container">
          <Input
            disabled={!disableAmountInputs}
            type="number"
            className="amount-input"
            placeholder={getText('minimum')}
            value={selectedFilters?.amountFrom ?? null}
            leftIcon={{ iconName: 'euro_symbol', className: 'euro-symbol-styles', color: 'black' }}
            onChange={(value) =>
              setSelectedFilters((prev) => ({ ...prev, amountFrom: value === '' ? null : Number(value) }))
            }
          />
          <Icon iconName="remove" className="remove-icon" />
          <Input
            disabled={!disableAmountInputs}
            type="number"
            className="amount-input"
            placeholder={getText('maximum')}
            value={selectedFilters?.amountTo ?? null}
            leftIcon={{ iconName: 'euro_symbol', className: 'euro-symbol-styles', color: 'black' }}
            onChange={(value) =>
              setSelectedFilters((prev) => ({ ...prev, amountTo: value === '' ? null : Number(value) }))
            }
          />
        </div>
      </div>
    </Modal>
  );
});

export default TransactionsFiltersModal;
