import { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, isNil, omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Dropdown, Input, useUrlParams } from 'components';
import { tableHeaderContainer } from './styles';

const ProfilesFilterBar = ({ getData }) => {
  const { clearQueryParams, setQueryParams, queryParams } = useUrlParams();
  const { getText } = useTranslations();
  const [inputOption, setInputOption] = useState(
    queryParams.userId ? 'userId' : queryParams.email ? 'email' : queryParams.username ? 'username' : 'email',
  );

  const searchOptions = ['userId', 'email', 'username']?.map((el) => ({
    label: getText(el),
    value: el,
  }));

  const handleInputOptionChange = ({ value }) => setInputOption(value);

  const handleSearchChange = debounce((value) => {
    if (isNil(inputOption)) return;

    clearQueryParams(searchOptions?.filter((el) => el.value !== inputOption)?.map((el) => el.value));
    !isNil(inputOption) &&
      setQueryParams({
        ...omit(
          queryParams,
          searchOptions?.filter((el) => el.value !== inputOption)?.map((el) => el.value),
        ),
        [inputOption]: value,
        pageIndex: 1,
        pageSize: 10,
      });
  }, 800);

  return (
    <div css={tableHeaderContainer}>
      <Dropdown
        noClear
        placeholder={getText('searchBy')}
        className="input-options-dropdown"
        value={inputOption ?? searchOptions[1]}
        options={searchOptions}
        onChange={handleInputOptionChange}
      />
      <Input
        small
        showClear
        placeholder={getText('search')}
        value={queryParams[inputOption] ?? ''}
        leftIcon={{ iconName: 'search' }}
        className="search-input"
        onChange={handleSearchChange}
        onKeyDown={(e) => e.keyCode === 13 && getData(queryParams)}
      />
    </div>
  );
};

ProfilesFilterBar.propTypes = {
  getData: PropTypes.func,
};

export default ProfilesFilterBar;
