import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { camelCase, debounce, isNil, omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, DateRangePicker, Dropdown, Icon, Input, useUrlParams, BookingsFiltersModal } from 'components';
import { BOOKING_STATUSES } from 'enums';
import { tableHeaderContainer } from './styles';

const DISPLAY_FORMAT = 'D MMM YYYY';

const BookingsFilterBar = ({ getData }) => {
  const { clearQueryParams, setQueryParams, queryParams } = useUrlParams();
  const { getText } = useTranslations();
  const { creationDate, status } = queryParams;
  const [inputOption, setInputOption] = useState(
    queryParams.bookingId ? 'bookingId' : queryParams.userId ? 'userId' : queryParams.email ? 'email' : 'bookingId',
  );
  const dateRangePickerRef = useRef();
  const datePickerRef = useRef();
  const modalRef = useRef();

  const statusOptions = Object.values(BOOKING_STATUSES).map((el) => ({
    label: getText(camelCase(el)),
    value: el,
  }));
  const searchOptions = ['bookingId', 'userId', 'email']?.map((el) => ({
    label: getText(el),
    value: el,
  }));

  const handleInputOptionChange = ({ value }) => setInputOption(value);

  const handleSearchChange = debounce((value) => {
    if (isNil(inputOption)) return;

    clearQueryParams(searchOptions?.filter((el) => el.value !== inputOption)?.map((el) => el.value));
    !isNil(inputOption) &&
      setQueryParams({
        ...omit(
          queryParams,
          searchOptions?.filter((el) => el.value !== inputOption)?.map((el) => el.value),
        ),
        [inputOption]:
          inputOption === 'email' || inputOption === 'userId'
            ? value
            : isNaN(Number(value))
            ? ''
            : Number(value) === 0
            ? ''
            : Number(value),

        pageIndex: 1,
        pageSize: 10,
      });
  }, 800);

  const handleStatusChange = (value) => {
    setQueryParams({ ...queryParams, status: value?.map((el) => el.value), pageIndex: 1, pageSize: 10 });
  };

  const handleDateRangeChange = (newParams) => {
    const checkIn = isNil(newParams?.startDate) ? null : moment(newParams?.startDate);
    const checkOut = isNil(newParams?.endDate) ? null : moment(newParams?.endDate);

    setQueryParams({ ...queryParams, checkIn, checkOut, pageIndex: 1, pageSize: 10 });
  };

  const handleSingleDateChange = (date) => {
    const singleDate = isNil(date) ? null : moment(date);

    setQueryParams({ ...queryParams, creationDate: singleDate, pageIndex: 1, pageSize: 10 });
  };

  return (
    <>
      <div css={tableHeaderContainer}>
        <div className="inputs-container">
          <Dropdown
            noClear
            placeholder={getText('searchBy')}
            className="input-options-dropdown"
            value={inputOption ?? searchOptions[1]}
            options={searchOptions}
            onChange={handleInputOptionChange}
          />
          <Input
            small
            showClear
            type={inputOption === 'email' || inputOption === 'userId' ? 'text' : 'number'}
            placeholder={getText('search')}
            value={queryParams[inputOption] ?? ''}
            leftIcon={{ iconName: 'search' }}
            className="search-input"
            onChange={handleSearchChange}
            onKeyDown={(e) => e.keyCode === 13 && getData(queryParams)}
          />
        </div>

        <div className="right-filters-container">
          <Dropdown
            small
            multiSelect
            placeholder={getText('status')}
            className="statuses-dropdown"
            value={status ?? null}
            options={statusOptions}
            onChange={handleStatusChange}
          />

          <DateRangePicker
            single
            showClear={false}
            placeholder={getText('createdDate')}
            ref={datePickerRef}
            date={creationDate && moment(creationDate)}
            onChange={handleSingleDateChange}
            displayFormat={DISPLAY_FORMAT}
            className="single-date-picker"
          />

          <DateRangePicker
            ref={dateRangePickerRef}
            startDate={queryParams?.checkIn}
            endDate={queryParams?.checkOut}
            onChange={handleDateRangeChange}
            displayFormat={DISPLAY_FORMAT}
            className="date-range-picker"
          />

          <div className="vertical-line" />

          <Button
            small
            type="default"
            className="default-button"
            leftIcon={<Icon iconName="filter_list" className="button-icon" size={18} />}
            onClick={() => modalRef.current?.open()}>
            {getText('moreFilters')}
          </Button>
        </div>
      </div>

      <BookingsFiltersModal ref={modalRef} />
    </>
  );
};

BookingsFilterBar.propTypes = {
  getData: PropTypes.func,
};

export default BookingsFilterBar;
